@import './tailwind.css';
@import url('https://fonts.googleapis.com/css2?family=Harmattan&family=Tajawal:wght@300;400;500;700&display=swap');

body{
    background-color: #374151;
    font-family: 'Tajawal';
    
}



